import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { VALUE_ENTER } from 'keycode-js';

import TextInput, { TextInputProps } from '@ui/components/TextInput';
import IconButton from '@ui/components/IconButton';
import SearchIcon from '@ui/icons/Search';
import { styled } from '@ui/styles';

const IButton = styled(IconButton)(({ theme }) => ({
	padding: 0,
}));

const Input = styled(TextInput, {
	shouldForwardProp: prop => prop !== 'avoidMaxWidth',
})<{ avoidMaxWidth?: boolean }>(({ theme, avoidMaxWidth }) => ({
	maxWidth: avoidMaxWidth ? undefined : 726,
}));

const FilterInput: React.FC<
	Omit<TextInputProps, 'variant' | 'ref' | 'value'> & {
		onSearch: (value: string) => void;
		icon?: React.ReactNode;
		searchWithEnter?: boolean;
		intitialValue?: string;
		avoidMaxWidth?: boolean;
	}
> = ({
	onSearch,
	icon,
	searchWithEnter,
	InputProps = {},
	intitialValue,
	avoidMaxWidth,
	...rest
}) => {
	const [search, setSearch] = React.useState(intitialValue || '');
	const handleChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			setSearch(event.target.value);
		},
		[],
	);
	const handleSearch = React.useCallback(() => {
		onSearch(search);
	}, [search, onSearch]);
	const handleKeyDown = React.useCallback(
		(e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			if (searchWithEnter && e.code === VALUE_ENTER && search) {
				onSearch(search);
			}
		},
		[search, searchWithEnter, onSearch],
	);
	return (
		<Input
			placeholder="Buscar"
			avoidMaxWidth={avoidMaxWidth}
			{...rest}
			value={search}
			onChange={handleChange}
			InputProps={{
				...InputProps,
				onKeyDown: handleKeyDown,
				endAdornment: (
					<InputAdornment position="end">
						<IButton
							aria-label="filter blog"
							disabled={!search}
							onClick={handleSearch}
						>
							{icon || <SearchIcon />}
						</IButton>
					</InputAdornment>
				),
			}}
		/>
	);
};

export default FilterInput;
