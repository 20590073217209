import React from 'react';

import Link from '@ui/components/Link';
import Text from '@ui/components/Text';
import Box, { BoxProps } from '@ui/components/Box';
import { styled } from '@ui/styles';
import { Row, Col as UICol, Image } from '@ui/components';
import { Instagram } from '@ui/icons';
import SubscribeInput from '@common-lib/modules/integration/components/SubscribeInput';
import { BrandCode, Role } from '@interfaces/index';
import AuthGuard from '@common-lib/components/auth/AuthGuard';

const AddressText = styled(Text)(({ theme }) => ({
	paddingTop: theme.spacing(3.5),
	[theme.breakpoints.down('sm')]: {
		textAlign: 'center',
	},
	fontSize: '0.75rem',
	lineHeight: '1rem',
}));
const Heading = styled(Text)(({ theme }) => ({
	fontWeight: '700',
	marginBottom: theme.spacing(2.5),
	marginTop: theme.spacing(3.5),
}));
const LinkText = styled(Text)(({ theme, gutterBottom }) => ({
	fontWeight: 400,
	fontSize: '0.75rem',
	lineHeight: '1rem',
	letterSpacing: '0.05rem',
	textTransform: 'uppercase',
	marginBottom: gutterBottom ? '0.75rem' : undefined,
	'& > svg': {
		marginBottom: -2,
		marginRight: theme.spacing(1),
	},
	cursor: 'pointer',
	textDecoration: 'none',
}));
const SocialLink = styled(Text)(({ theme, gutterBottom }) => ({
	fontWeight: 400,
	fontSize: '0.875rem',
	lineHeight: '1rem',
	marginBottom: gutterBottom ? '1.25rem' : undefined,
	'& > svg': {
		marginBottom: -5,
		marginRight: theme.spacing(1.25),
	},
	cursor: 'pointer',
	textDecoration: 'none',
}));
const Col = styled(UICol)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
}));
const DesktopCol = styled(UICol)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	[theme.breakpoints.down('sm')]: {
		display: 'none',
	},
}));
const MobileCol = styled(UICol)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	[theme.breakpoints.up('sm')]: {
		display: 'none',
	},
}));
const InfoWrapper = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	[theme.breakpoints.down('sm')]: {
		alignItems: 'center',
		paddingTop: theme.spacing(9),
	},
}));

const Info: React.FC<Omit<BoxProps, 'ref'>> = props => {
	return (
		<InfoWrapper {...props}>
			<Image
				src="/logo_semina.png"
				alt="logo"
				height={27}
				width={150}
				priority
			/>
			<AddressText variant="h6" component="div">
				Rua Maracá, 658 - Vila Guarani <br />
				São Paulo - SP - Brasil
				<br /> 04313-210 <br />
				Fone: (11) 5014-7800
			</AddressText>
		</InfoWrapper>
	);
};
const DefaultFooter: React.FC<
	Omit<BoxProps, 'ref'> & { noRequestBtn?: boolean }
> = ({ pb, pt, noRequestBtn, ...props }) => {
	return (
		<Box {...props} pb={pb || 8}>
			<Row>
				<DesktopCol xs={12} md={4}>
					<Box pt={3}>
						<Info />
					</Box>
				</DesktopCol>
				<Col xs={12} md={8}>
					{noRequestBtn ? null : <SubscribeInput pb={1.25} />}

					<Row spacing={1}>
						<Col xs={12} md={4}>
							<Heading color="primary">Grupo Semina</Heading>
							<Link href="/sobre" passHref legacyBehavior>
								<LinkText component="a" gutterBottom>
									SOBRE
								</LinkText>
							</Link>
							<Link href="/contato" passHref legacyBehavior>
								<LinkText component="a" gutterBottom>
									CONTATO
								</LinkText>
							</Link>
							{/* <Link href="/termos" passHref legacyBehavior>
								<LinkText component="a" gutterBottom>
									TERMOS
								</LinkText>
							</Link> */}
							{/* <Link href="/news" passHref legacyBehavior>
								<LinkText component="a" gutterBottom>
									IMPRENSA
								</LinkText>
							</Link> */}
							{/* <Heading color="primary">
								Farmacovigilancia
							</Heading>
							<Link href="/" passHref legacyBehavior>
								<LinkText component="a">
									<Warning />
									REPORTAR
								</LinkText>
							</Link> */}
							<Link href="/mapa" passHref legacyBehavior>
								<LinkText component="a">
									ONDE ENCONTRAR
								</LinkText>
							</Link>
						</Col>
						<Col xs={12} md={4}>
							<AuthGuard
								requireRoles={[
									Role.ADMIN,
									Role.DOCTOR,
									Role.EVENT_AGENT,
									Role.EVENT_MANAGER,
								]}
							>
								<Heading color="primary">Marcas</Heading>
								<Link
									href={`/${BrandCode.AMAMENTE}`}
									passHref
									legacyBehavior
								>
									<LinkText component="a" gutterBottom>
										AMAMENTE
									</LinkText>
								</Link>
								<Link
									href={`/${BrandCode.DELLA}`}
									passHref
									legacyBehavior
								>
									<LinkText component="a" gutterBottom>
										DELLA
									</LinkText>
								</Link>
								<Link
									href={`/${BrandCode.KY}`}
									passHref
									legacyBehavior
								>
									<LinkText component="a" gutterBottom>
										KY
									</LinkText>
								</Link>
								<Link
									href={`/${BrandCode.KYCLINICAL}`}
									passHref
									legacyBehavior
								>
									<LinkText component="a" gutterBottom>
										K-Y clinical
									</LinkText>
								</Link>
								<Link
									href={`/${BrandCode.NUAA}`}
									passHref
									legacyBehavior
								>
									<LinkText component="a" gutterBottom>
										NUAÁ
									</LinkText>
								</Link>
							</AuthGuard>
						</Col>
						<Col xs={12} md={4}>
							<Heading color="primary">Instagram</Heading>
							<Link
								href="https://www.instagram.com/seminaeducativa/?hl=pt"
								target="_blank"
								passHref
								legacyBehavior
							>
								<SocialLink
									component="a"
									gutterBottom
									// @ts-ignore
									target="_blank"
								>
									<Instagram />
									@seminaeducativa
								</SocialLink>
							</Link>
							<Link
								href="https://www.instagram.com/kyoficial/?hl=pt"
								target="_blank"
								passHref
								legacyBehavior
							>
								<SocialLink
									component="a"
									gutterBottom
									// @ts-ignore
									target="_blank"
								>
									<Instagram />
									@kyoficial
								</SocialLink>
							</Link>
							<Link
								href="https://www.instagram.com/sounuaa/?hl=pt"
								target="_blank"
								passHref
								legacyBehavior
							>
								<SocialLink
									component="a"
									gutterBottom
									// @ts-ignore
									target="_blank"
								>
									<Instagram />
									@sounuaa
								</SocialLink>
							</Link>
							<Link
								href="https://www.instagram.com/amamente/?hl=pt"
								target="_blank"
								passHref
								legacyBehavior
							>
								<SocialLink
									component="a"
									// @ts-ignore
									target="_blank"
								>
									<Instagram />
									@amamente
								</SocialLink>
							</Link>
						</Col>
					</Row>
				</Col>
				<MobileCol xs={12} md={4}>
					<Box pt={3}>
						<Info />
					</Box>
				</MobileCol>
			</Row>
		</Box>
	);
};

export default DefaultFooter;
