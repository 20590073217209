import React from 'react';
import { useMutation } from '@apollo/client';
import { BrandCode } from '@interfaces';

import { Box, BoxProps } from '@ui/components';
import { RightArrow } from '@ui/icons';

import FilterInput from '@common-lib/modules/blog/components/FilterInput';
import MUTATION_SUBSCRIBE from '@common-lib/modules/integration/graphql/mutationRDStationSubscribe.graphql';
import emailValidator from '@common-lib/validators/email';
import useStatus from '@common-lib/modules/status/hooks';

const SubscribeInput: React.FC<
	Omit<BoxProps, 'ref'> & { brandCode: BrandCode | 'semina' }
> = ({ brandCode, ...props }) => {
	const [force, setForce] = React.useState(0);
	const [mutation] = useMutation(MUTATION_SUBSCRIBE);
	const { addError, addSuccess } = useStatus();
	const handleSearch = React.useCallback(
		(value: string) => {
			if (emailValidator(value)) {
				mutation({
					variables: { email: value, brandCode },
					onError: () => {
						addError('Falhou ao se inscrever');
					},
					onCompleted: () => {
						setForce(prev => ++prev);
						addSuccess('Inscrito com sucesso');
					},
				});
			}
		},
		[brandCode],
	);

	return (
		<Box {...props} width={'100%'}>
			<FilterInput
				key={`key-${force}`}
				intitialValue=""
				onSearch={handleSearch}
				fullWidth
				searchWithEnter
				avoidMaxWidth
				color="primary"
				placeholder="Receba novidades por e-mail"
				icon={<RightArrow color="primary" />}
			/>
		</Box>
	);
};

export default SubscribeInput;
